import { get, post, del, put, postFd } from "../../utils/axios";

export const papularshow = (payload) => {
  return post("VideosGet", payload, false);
};
export const documentaries = (payload) => {
  return post("VideosGet", payload, false);
};
export const mostViewed = (payload) => {
  return post("VideosGet", payload, false);
};
export const completearchive = (payload) => {
  return post("VideosGet", payload, false);
};
export const adventureclub = (payload) => {
  return post("VideosGet", payload, false);
};
