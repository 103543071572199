import { Layout, Dropdown, Avatar, Col, Row, Button, Menu } from "antd";
import {
  UserOutlined,
  MenuUnfoldOutlined,
  AppstoreOutlined,
  ContainerOutlined,
  DesktopOutlined,
  MenuFoldOutlined,
  PieChartOutlined,
  MailOutlined,
  GlobalOutlined,
  PhoneOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons";
import logo from "../../assets/images/logo.png";
import { useState } from "react";
import { NavLink } from "react-bootstrap";
import { Link } from "react-router-dom";
import SignUp from "../signuplogin/SignUp";
import LoginForm from "../signuplogin/LoginForm";
import MyPage from "../Content/MyPage";
import TopHeader from "../header/TopHeader";
import SidebarSlider from "../slider/SidebarSlider";
import MainFooter from "../footer/MainFooter";
function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}
const item = [
  getItem("Latest Videos", "1", <PieChartOutlined className="icon-large" />),
  getItem("Discover Shorts", "2", <DesktopOutlined className="icon-large" />),
  getItem("Dekho Pakistan", "3", <ContainerOutlined className="icon-large" />),
  getItem(
    "Paharon Ka Safar",
    "4",
    <ContainerOutlined className="icon-large" />
  ),
  getItem("Street Food", "5", <ContainerOutlined className="icon-large" />),
  getItem("Hotels For You", "6", <ContainerOutlined className="icon-large" />),
  getItem("De-Bikers", "7", <ContainerOutlined className="icon-large" />),
  getItem(
    "Travelogue of The Week",
    "8",
    <ContainerOutlined className="icon-large" />
  ),
];
const { Header, Footer, Sider, Content } = Layout;
const headerStyle = {
  textAlign: "center",
  color: "#fff",
  height: 64,
  paddingInline: 50,
  lineHeight: "64px",
  backgroundColor: "#7dbcea",
};
const contentStyle = {
  textAlign: "center",
  minHeight: 120,
  lineHeight: "120px",
  color: "#fff",
  backgroundColor: "#108ee9",
};
const siderStyle = {
  textAlign: "center",
  lineHeight: "120px",
  color: "#fff",
  backgroundColor: "#3ba0e9",
};
const footerStyle = {
  textAlign: "center",
  color: "#fff",
  backgroundColor: "#7dbcea",
};
const HomePage = () => {
  const items = [
    {
      label: (
        <span className="dropdown-avatar">
          <SignUp />
        </span>
      ),
      key: "0",
    },
    {
      label: (
        <span className="dropdown-avatar">
          <LoginForm />
        </span>
      ),
      key: "1",
    },
    // {
    //   type: "divider",
    // },
    // {
    //   label: "3rd menu item",
    //   key: "3",
    // },
  ];
  const [activeTab, setActiveTab] = useState(0);
  const [collapsed, setCollapsed] = useState(false);
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  const handleTabClick = (tabIndex, event) => {
    setActiveTab(tabIndex);
  };

  return <MyPage />;
};
export default HomePage;
