import React, { useEffect, useState } from "react";
import Videos from "../Videos/Videos";
import { makeRequest, notify } from "../../helper/helpers";
import { papularshow } from "./requst";
import { Skeleton } from "antd";

const PopularShows = ({ type }) => {
  const [loader, setLoader] = useState(false);
  const [videosList, setVideosList] = useState([]);

  // api call
  useEffect(() => {
    getVideosList();
  }, []);
  const getVideosList = () => {
    let payload = {};
    if (type) {
      payload.category = type;
    }
    makeRequest(setLoader, papularshow, payload, onSuccess, onError);
  };
  const onSuccess = (videos) => {
    const filteredData = videos.data.filter((item) => item.video !== null);
    setVideosList(filteredData);
    // notify(" Add Successfully");
  };
  // form.setFieldsValue({ heading: "chand" });
  const onError = (err, data) => {
    notify("something went wrong");
  };
  if (loader) return <Skeleton active />;
  return <Videos items={videosList} loader={loader} />;
};

export default PopularShows;
