import { Button, Checkbox, Form, Input, Col, Row, Modal } from "antd";
import googlelogo from "../../assets/images/google-logo.jpg";
import { makeRequest, notify } from "../../helper/helpers";
import React, { useState } from "react";
import LoginForm from "./LoginForm";
import { signup } from "./requests";
const SignUp = ({ SignUpModel, type }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const showModal = ({}) => {
    setIsModalOpen(true);
    if (type == "loginform") {
      SignUpModel(false);
    }
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const onFinish = (payload) => {
    // let payload = {};

    makeRequest(setLoader, signup, payload, onSuccess, onError);
  };
  const onSuccess = (data) => {
    setIsModalOpen(false);

    notify(" Add Successfully");
  };
  // form.setFieldsValue({ heading: "chand" });
  const onError = (err, data) => {
    setIsModalOpen(false);

    notify("something went wrong");
  };
  return (
    <>
      {type == "loginform" ? (
        <span className="btn-login" onClick={showModal}>
          Sign Up
        </span>
      ) : (
        <span className="dropdown-menu" onClick={showModal}>Sign Up</span>
      )}
      <Modal
        title={
          <div className="tital-signup">
            <span>Sign Up</span>
          </div>
        }
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Row justify="center">
          <Form
            layout="vertical"
            onFinish={onFinish}
            // onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Row justify="space-around">
              <Col span={22}>
                <Form.Item
                  label=" Name"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please input your  name!",
                    },
                  ]}
                >
                  <Input placeholder=" name" />
                </Form.Item>
              </Col>

              {/* <Col span={22}>
                <Form.Item
                  label="Username"
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "Please input your username!",
                    },
                  ]}
                >
                  <Input placeholder="username" />
                </Form.Item>
              </Col> */}
              <Col span={22}>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      type: "email",
                      required: true,
                      message: "Please input your email!",
                    },
                  ]}
                >
                  <Input placeholder="email" />
                </Form.Item>
              </Col>
              <Col span={22}>
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password className="custom-password-field" placeholder="password" />
                </Form.Item>
              </Col>
              <Col span={22}>
                <Form.Item
                  label="Confirm Password"
                  name="confirm password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your confirm password!",
                    },
                  ]}
                >
                  <Input.Password className="custom-password-field" placeholder="confirm password" />
                </Form.Item>
              </Col>

              <Col span={22}>
                <Form.Item
                  name="remember"
                  valuePropName="checked"
                  wrapperCol={{ offset: 8, span: 16 }}
                >
                  <Checkbox>Remember me</Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Row justify="center">
              <Col span={22}>
                <div class="field button-field">
                  <button id="input-btn" htmlType="submit" loader={loader}>
                    Signup
                  </button>
                </div>
              </Col>
            </Row>
            <div class="form-link">
              <span>
                Already have an account?
                <span>
                  <LoginForm SignUpModel={setIsModalOpen} type="signupform" />
                </span>
              </span>
            </div>
            <Row justify="center">
              <Col span={22}>
                <div class="line"></div>
              </Col>
            </Row>
            <Row justify="center">
              <Col span={22}>
                <div class="media-options">
                  <a href="/" class="field google">
                    <img class="google-img" src={googlelogo} alt="" />
                    <span>Login with Google</span>
                  </a>
                </div>
              </Col>
            </Row>
          </Form>
          {/* </Col> */}
        </Row>
      </Modal>
    </>
  );
};
export default SignUp;
