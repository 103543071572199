import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import HomePage from "./Modules/HomePage/HomePage"
import HomePage from "./Modules/HomePage/HomePage";
import SignUp from "./Modules/signuplogin/SignUp";
import "../src/Style/CustomStyle.css";
import LoginForm from "./Modules/signuplogin/LoginForm";
import ForgotPassword from "./Modules/signuplogin/ForgotPassword";
import Home from "./Modules/AllTabs/Home";
import PopularShows from "./Modules/AllTabs/PopularShows";
import Documentaries from "./Modules/AllTabs/Documentaries";
import MostViewed from "./Modules/AllTabs/MostViewed";
import AdventureClub from "./Modules/AllTabs/AdventureClub";
import CompleteArchive from "./Modules/AllTabs/CompleteArchive";
import SidebarSlider from "./Modules/slider/SidebarSlider";
import { Layout } from "antd";
import TopHeader from "./Modules/header/TopHeader";
import MainFooter from "./Modules/footer/MainFooter";
const App = () => {
  return (
    <BrowserRouter>
      <Layout>
        <SidebarSlider />
        <Layout>
          <TopHeader />
          <Layout>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="popularshows" element={<PopularShows type = "Popular Shows" />} />
              <Route path="documentaries" element={<PopularShows type = "Popular Shows" />} />
              <Route path="mostviewed" element={<MostViewed />} />
              <Route path="completearchive" element={<CompleteArchive />} />
              <Route path="adventureclub" element={<AdventureClub />} />
              <Route path="/" element={<Home />} />
            </Routes>
          </Layout>
          <Layout>
            <MainFooter />
          </Layout>
        </Layout>
      </Layout>
    </BrowserRouter>
  );
};

export default App;
