import { Button, Checkbox, Form, Input, Col, Row, Modal } from "antd";
import googlelogo from "../../assets/images/google-logo.jpg";
import { Link, useNavigate } from "react-router-dom";
import { makeRequest, notify } from "../../helper/helpers";
import React, { useState } from "react";
import { sendEmail, sendotp, updatePassword } from "./requests";
const ForgotPassword = ({ loginModal }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const [visible, setVisible] = useState("reset");
  const showModal = () => {
    setIsModalOpen(true);
    loginModal(false);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const onOtpSuccess = (res) => {
    notify("Verify OTP");
    setVisible("conform password");
  };
  const onOtpError = (err, res) => {
    notify("Invalid OTP");
  };
  const onFinish = async (payload) => {
    if (visible == "otp") {
      let paload = {
        ...payload,
      };
      makeRequest(setLoader, sendotp, paload, onOtpSuccess, onOtpError);
    } else if (visible == "reset") {
      let load = {
        ...payload,
      };
      makeRequest(setLoader, sendEmail, load, onSuccess, onError);
    } else if (visible == "conform password") {
      let load = {
        ...payload,
      };
      makeRequest(setLoader, updatePassword, load, onSuccessUpdate, onError);
    }
  };
  const onSuccessUpdate = (res) => {
    // setVisible("otp");
    notify("Password updated Successfuly");
    navigate("/");
  };
  const onSuccess = (res) => {
    setVisible("otp");
    notify("Email sand Successfully");
  };

  const onError = (err, res) => {
    notify("Error! Something went wrong ");
  };
  return (
    <>
      <p onClick={showModal}>Forgot password?</p>
      <Modal
        title={
          <div className="tital-signup">
            <span>Forgot Password</span>
          </div>
        }
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <div justify="center">
          <Form
            layout="vertical"
            onFinish={onFinish}
            // onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Row justify="center">
              <Col span={22}>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      type: "email",
                      required: true,
                      message: "Please input your email!",
                    },
                  ]}
                >
                  <Input
                    placeholder="email"
                    disabled={visible != "reset" ? true : false}
                  />
                </Form.Item>
              </Col>
              {visible == "otp" && (
                <Col span={22}>
                  <Form.Item
                    label="Verify OTP"
                    name="otp"
                    rules={[
                      {
                        type: "otp",
                        required: true,
                        message: "Please input your otp!",
                      },
                    ]}
                  >
                    <Input placeholder="Verify otp" />
                  </Form.Item>
                </Col>
              )}
              {visible == "conform password" && (
                <Col span={22}>
                  <Row>
                    <Col span={24}>
                      <Col span={22}>
                        <Form.Item
                          label="Password"
                          name="password"
                          rules={[
                            {
                              required: true,
                              message: "Please input your password!",
                            },
                          ]}
                        >
                          <Input.Password className="custom-password-field" placeholder="password" />
                        </Form.Item>
                      </Col>
                      <Col span={22}>
                        <Form.Item
                          label="Confirm Password"
                          name="confirm_password"
                          rules={[
                            {
                              required: true,
                              message: "Please input your confirm password!",
                            },
                          ]}
                        >
                          <Input.Password className="custom-password-field" placeholder="confirm password" />
                        </Form.Item>
                      </Col>
                    </Col>
                  </Row>
                </Col>
              )}

              <Col span={22}>
                <div class="field button-field">
                  <button loading={loader} htmlType="submit">
                    {visible == "otp" && "Verify OTP"}
                    {visible == "reset" && "Send Email"}
                    {visible == "conform password" && "Conform Password"}
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>
    </>
  );
};
export default ForgotPassword;
