import React, { useEffect, useState } from "react";
import { Col, Row, Skeleton } from "antd";
import { makeRequest, notify } from "../../helper/helpers";
import { mypagevideos } from "./requst";

const MyPage = () => {
  const [loader, setLoader] = useState(false);
  const [videosList, setVideosList] = useState([]);

  // api call
  useEffect(() => {
    getVideosList();
  }, []);
  const getVideosList = () => {
    let payload = {};
    makeRequest(setLoader, mypagevideos, payload, onSuccess, onError);
  };
  const onSuccess = (videos) => {
    const filteredData = videos.data.filter((item) => item.video !== null);
    setVideosList(filteredData);
    // notify(" Add Successfully");
  };
  // form.setFieldsValue({ heading: "chand" });
  const onError = (err, data) => {
    notify("something went wrong");
  };
  if (loader) return <Skeleton active />;
  return (
    <div>
      <div className="Content">
        <Row justify="space-evenly">
          <Col span={16} className="video-main-out">
            <div className="video-main">
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/Mj2eiHorCQE"
                title="Kheti (Farming) | Laddi Chahal Ft. Parmish Verma | Shekh | New Punjabi Songs 2023 | SDA Studios"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </Col>
          <Col span={7}>
            <Row>
              <Col className="card-add" span={24}>
                <div className="card-add-1"></div>
              </Col>
              <Col className="card-add" span={24}>
                <div className="card-add-2"></div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify="space-evenly">
          <Col className="video-cards-out" span={6}>
            <div className="video-cards"></div>
          </Col>
        </Row>
        <Row justify="space-evenly">
          <Col className="video-shorts-out" span={4}>
            <div className="video-shorts"></div>
          </Col>
          <Col className="video-shorts-out" span={4}>
            <div className="video-shorts"></div>
          </Col>
          <Col className="video-shorts-out" span={4}>
            <div className="video-shorts"></div>
          </Col>
          <Col className="video-shorts-out" span={4}>
            <div className="video-shorts"></div>
          </Col>
          <Col className="video-shorts-out" span={4}>
            <div className="video-shorts"></div>
          </Col>
          <Col className="video-shorts-out" span={4}>
            <div className="video-shorts"></div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default MyPage;
