import { Col, Row } from "antd";
import React from "react";

const Videos = ({ items }) => {
  console.log(items);
  const getVideoIdFromUrl = (url) => {
    const regex =
      /(?:https?:\/\/(?:www\.)?)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?/;
    const match = url.match(regex);

    if (match && match[1]) {
      return match[1];
    }

    return null;
  };

  return (
    <div style={{ backgroundColor: "white" }}>
      <Row justify="space-evenly">
        {items.map((url, index) => (
          <Col className="video-cards-out" span={6}>
            <div className="video-cards">
              <iframe
                style={{ borderRadius: "7px" }}
                width="100%" // Set the desired width of the video
                height="100%" // Set the desired height of the video
                src={`https://www.youtube.com/embed`}
                allowFullScreen
              />
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Videos;
