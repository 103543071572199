import { get, post, del, put, postFd } from "../../utils/axios";

// const api = "subjects";

// export const addSubject = (payload) => {
//   return post(api, payload, false);
// };
// export const getSubjects = (payload) => {
//   return get(api, payload, false);
// };

// };
export const signup = (payload) => {
  return post("register", payload, false);
};
export const login = (payload) => {
  return post("login", payload, false);
};
export const sendEmail = (payload) => {
  return post("forgotPassword", payload, false);
};
export const sendotp = (payload) => {
  return post("otpVerification", payload, false);
};
export const updatePassword = (payload) => {
  return post("updatePassword", payload, false);
};
