import { Button, Checkbox, Form, Input, Col, Row, Modal } from "antd";
import googlelogo from "../../assets/images/google-logo.jpg";
import { Link } from "react-router-dom";
import { makeRequest, notify } from "../../helper/helpers";
import React, { useState } from "react";
import ForgotPassword from "./ForgotPassword";
import SignUp from "./SignUp";
import { login } from "./requests";
const LoginForm = ({ SignUpModel, type }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
    if (type == "signupform") {
      SignUpModel(false);
    }
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const onFinish = (payload) => {
    // let payload = {};

    makeRequest(setLoader, login, payload, onSuccess, onError);
  };
  const onSuccess = (data) => {
    setIsModalOpen(false);
    notify(" Add Successfully");
  };
  // form.setFieldsValue({ heading: "chand" });
  const onError = (err, data) => {
    setIsModalOpen(false);
    notify("something went wrong");
  };
  return (
    <>
      {type == "signupform" ? (
        <span className="btn-login" onClick={showModal}>
          Login
        </span>
      ) : (
        <span className="dropdown-menu" onClick={showModal}>
          Login
        </span>
      )}
      <Modal
        title={
          <div className="tital-signup">
            <span>Login</span>
          </div>
        }
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Row justify="center">
          {/* <Col className="signup-form"> */}
          <Form
            layout="vertical"
            onFinish={onFinish}
            // onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Row justify="space-around">
              <Col span={22}>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      type: "email",
                      required: true,
                      message: "Please input your email!",
                    },
                  ]}
                >
                  <Input placeholder="email or username" />
                </Form.Item>
              </Col>
              <Col span={22}>
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password
                    className="custom-password-field"
                    placeholder="password"
                  />
                </Form.Item>
              </Col>

              <Col span={11}>
                <Form.Item
                  name="remember"
                  valuePropName="checked"
                  wrapperCol={{ offset: 8, span: 16 }}
                >
                  <Checkbox>Remember me</Checkbox>
                </Form.Item>
              </Col>
              <Col className="forgot-password" span={11}>
                <span>
                  <ForgotPassword loginModal={setIsModalOpen} />
                </span>
              </Col>
            </Row>
            <Row justify="center">
              <Col span={22}>
                <div class="field button-field">
                  <button>Login</button>
                </div>
              </Col>
            </Row>
            <div class="form-link">
              <span>
                Don't have an account?
                <span>
                  <SignUp SignUpModel={setIsModalOpen} type="loginform" />
                </span>
              </span>
            </div>
            <Row justify="center">
              <Col span={22}>
                <div class="line"></div>
              </Col>
            </Row>
            <Row justify="center">
              <Col span={22}>
                <div class="media-options">
                  <a href="/" class="field google">
                    <img class="google-img" src={googlelogo} alt="" />
                    <span>Login with Google</span>
                  </a>
                </div>
              </Col>
            </Row>
          </Form>
          {/* </Col> */}
        </Row>
      </Modal>
    </>
  );
};
export default LoginForm;
