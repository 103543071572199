import React, { useEffect, useState } from "react";
import Videos from "../Videos/Videos";
import { makeRequest, notify } from "../../helper/helpers";
import { Skeleton } from "antd";
import { mostViewed } from "./requst";

const MostViewed = () => {
  const [loader, setLoader] = useState(false);
  const [videosList, setVideosList] = useState([]);

  // api call
  useEffect(() => {
    getVideosList();
  }, []);
  const getVideosList = () => {
    let payload = {};
    makeRequest(setLoader, mostViewed, payload, onSuccess, onError);
  };
  const onSuccess = (videos) => {
    const filteredData = videos.data.filter((item) => item.video !== null);
    setVideosList(filteredData);
    notify(" Add Successfully");
  };
  // form.setFieldsValue({ heading: "chand" });
  const onError = (err, data) => {
    notify("something went wrong");
  };
  if (loader) return <Skeleton active />;
  return <Videos items={videosList} />;
};

export default MostViewed;
