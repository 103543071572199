import React, { useState } from "react";
import { Button, Col, Layout, Menu, Row } from "antd";
import Item from "antd/es/list/Item";
import {
  ContainerOutlined,
  DesktopOutlined,
  PieChartOutlined,
} from "@ant-design/icons";
import logo from "../../assets/images/logo.png";
function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}
// import SidebarSlider from "../slider/SidebarSlider";
const { Sider } = Layout;

const item = [
  getItem("Latest Videos", "1", <PieChartOutlined className="icon-large" />),
  getItem("Discover Shorts", "2", <DesktopOutlined className="icon-large" />),
  getItem("Dekho Pakistan", "3", <ContainerOutlined className="icon-large" />),
  getItem(
    "Paharon Ka Safar",
    "4",
    <ContainerOutlined className="icon-large" />
  ),
  getItem("Street Food", "5", <ContainerOutlined className="icon-large" />),
  getItem("Hotels For You", "6", <ContainerOutlined className="icon-large" />),
  getItem("De-Bikers", "7", <ContainerOutlined className="icon-large" />),
  getItem(
    "Travelogue of The Week",
    "8",
    <ContainerOutlined className="icon-large" />
  ),

  // getItem(
  //   "Navigation Two",
  //   "sub2",
  //   <AppstoreOutlined className="icon-large" />,
  //   [
  //     getItem("Option 9", "9"),
  //     getItem("Option 10", "10"),
  //     getItem("Submenu", "sub3", null, [
  //       getItem("Option 11", "11"),
  //       getItem("Option 12", "12"),
  //     ]),
  //   ]
  // ),
];
const SidebarSlider = () => {
  const [collapsed, setCollapsed] = useState(false);
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  return (
    <div>
      <Sider collapsible collapsed={collapsed} onCollapse={toggleCollapsed}>
        <div>
          <Row justify="space-between">
            <Col>
              <Button
                type="primary"
                onClick={toggleCollapsed}
                style={{
                  marginBottom: 16,
                  marginTop: 20,
                }}
              >
                {collapsed ? (
                  <svg
                    className="svg-slider"
                    height="24"
                    viewBox="0 0 24 24"
                    width="24"
                    focusable="false"
                  >
                    <path d="M21 6H3V5h18v1zm0 5H3v1h18v-1zm0 6H3v1h18v-1z"></path>
                  </svg>
                ) : (
                  <svg
                    className="svg-slider"
                    height="24"
                    viewBox="0 0 24 24"
                    width="24"
                    focusable="false"
                  >
                    <path d="M21 6H3V5h18v1zm0 5H3v1h18v-1zm0 6H3v1h18v-1z"></path>
                  </svg>
                )}
              </Button>
            </Col>
            {!collapsed && (
              <Col>
                <img className="header-logo" src={logo} alt="" />
              </Col>
            )}
          </Row>

          <Menu
            defaultSelectedKeys={["1"]}
            defaultOpenKeys={["sub1"]}
            mode="inline"
            theme="dark"
            inlineCollapsed={collapsed}
            items={item}
            className="custom-menu"
          />
        </div>
      </Sider>
    </div>
  );
};

export default SidebarSlider;
